<template>
    <div>
        <form @submit.prevent="login">
            <!-- TODO validar texto de alerta -->
            <label for="email">Correo electrónico</label>
            <vx-input-group>
                <vs-input
                    id="email"
                    name="email"
                    type="email"
                    :danger="!!validate.emailError"
                    v-model="email"
                    autofocus
                />
            </vx-input-group>
            <p v-if="!!validate.emailError" class="text-danger text-sm ">Ingrese una dirección de correo electronico válida.</p>
            <br/>
        
            <label for="password">Contraseña</label>
            <vx-input-group>
                <vs-input
                    id="password"
                    name="password"
                    :danger="!!validate.passwordError"
                    :type=" isVisiblePassword ? 'text' : 'password' "
                    v-model="password"
                />

                <template slot="append">
                    <div class="append-text btn-addon">
                        <vs-button
                            color="dark"
                            type="flat"
                            icon-pack="feather"
                            :icon=" isVisiblePassword ? 'icon-eye' : 'icon-eye-off'"
                            @click="isVisiblePassword = !isVisiblePassword"
                        ></vs-button>
                    </div>
                </template>
            </vx-input-group>
            <p class="text-danger text-sm" v-if="!!validate.passwordError">Ingrese una contraseña válida.</p>
            <br/>

            <div class="flex flex-wrap justify-between my-5">
                <vs-checkbox v-model="rememberMe" class="mb-3">
                    Recordarme
                </vs-checkbox>
            </div>

            <div class="w-full">
                <button type="submit" class="vs-component vs-button w-full rounded-full mb-5 vs-button-primary" style="background-color:#e32112;">
                    <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                    <!---->
                    <span class="vs-button-text vs-button--text">Inicia Sesión</span>
                    <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                </button>

                <button type="button" class="vs-component vs-button w-full rounded-full mb-5 text-primary" @click="recoverPwdDialog = true">
                    <span class="vs-button-text text-dark">¿Olvidaste tu contraseña?</span>
                </button>
            </div>
        </form>

        <vs-prompt :active.sync="recoverPwdDialog" buttons-hidden>
            <div class="w-full">
                <h3 class="inline-block mt-3 text-info">Recuperar contraseña</h3>

                <vs-button  class="float-right" @click="recoverPwdDialog = false" color="transparent" text-color="#666">
                    <feather-icon icon='XIcon'  />
                </vs-button>
            </div>
        
            <form @submit.prevent="recoveryPassword()" class="mt-10">
                <vx-input-group>
                <vs-input
                        id="email"
                        name="email"
                        type="email"
                        label="Ingrese su dirección de correo electrónico"
                        :danger="!!validate.emailError"
                        v-model="email"
                        autofocus
                    />
                </vx-input-group>
                <p v-if="!!validate.emailError" class="text-danger text-sm ">Ingrese una dirección de correo electronico válida.</p>
                <br/>

                <button type="submit" class="vs-component vs-button w-full rounded-full mb-5 vs-button-primary vs-button-filled">
                    <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                    <!---->
                    <span class="vs-button-text vs-button--text">Recuperar contraseña</span>
                    <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                </button>
            </form>
        </vs-prompt>
    </div>
</template>

<script lang="ts">
import Vue      from 'vue';
import Cookies  from 'js-cookie';
import firebase from 'firebase/app';
import 'firebase/auth';

import { Validation } from '../../../plugins/validation';

export default Vue.extend({
    data() {
        return {
            email            : '',
            password         : '',
            isVisiblePassword: false,
            rememberMe       : false,
            validate         : new Validation(),
            recoverPwdDialog : false,
        }
    },

    watch: {
        email( val ) {
            this.validate.validateEmail( val );
        }
    },

    created() {
        const isLoggedIn          = this.$store.state.auth.isUserLoggedIn();
        const firebaseCurrentUser = firebase.auth().currentUser;
        const emailSaved          = Cookies.get( 'email' );

        if( isLoggedIn && firebaseCurrentUser ) {
            window.location.href = '/';
        }
        
        if( emailSaved ) {
            this.email      = emailSaved;
            this.rememberMe = true;
        }
        
    },

    methods:{
        recoveryPassword() {
            const isValidEmail = this.validate.validateEmail( this.email );
            
            if( !isValidEmail ) {
                this.$vs.notify({
                    title: 'Ingrese un correo electrónico válido',
                    // text: 'Only support uploading one file!',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
                return;
            }

            this.$vs.loading();
            this.$store.dispatch( 'auth/recoveryFbPwd', this.email )
                .then( () => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Recuperación de contraseña',
                        text: 'Si tú correo se encuentra registrado en el sistema se te enviará un correo electrónico con las instrucciones de recuperación',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success',
                        time: 7000,
                    });
                    this.recoverPwdDialog = false;
                });
        },

        login() {
            const isValidEmail = this.validate.validateEmail( this.email );
            
            if( !isValidEmail ) {
                this.$vs.notify({
                    title: 'Ingrese un correo electrónico válido',
                    // text: 'Only support uploading one file!',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
                return;
            }

            this.$vs.loading();

            const payload = {
                rememberMe: this.rememberMe,
                userDetails: {
                    email: this.email,
                    password: this.password,
                },
                notify: this.$vs.notify,
                closeAnimation: this.$vs.loading.close,
                loginSiti: true,
            };

            this.$store.dispatch('auth/loginAttempt', payload);
        }
    },

    computed: {
        validateForm() {
            return !this.errors.any() && this.email != '' && this.password != '';
        }
    }
})
</script>

<style lang="scss">
a.recovery-link{
    text-align: center;
    display: block;
    cursor: pointer;
}
</style>