<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Miguel A. Arenas
    Author URL: https://github.com/mxaxaxbx
========================================================================================== -->

<template>
  <div class="h-screen vx-row" id="page-login">
    
    <div class="vx-col w-full mt-10">
      <!-- <router-link to="/" aria-label="go to index page" class="ml-10 mt-10">
        <img width="60" height="60" :src="require('@/assets/images/elements/logo-rounded.png')" class="" alt="Logo sakura" title="Ir al inicio" /> 
      </router-link> -->
    </div>
    
    <div class="vx-col hidden lg:block lg:w-1/2 mt-2">
      <div class="flex justify-center img-center ">
        <img :src="require('@/assets/images/elements/logo.png')" alt="login" class="w-2/3 mt-44">
      </div>
    </div>
          
    <div class="vx-col sm:w-full md:w-full lg:w-1/2 mt-2">
      <div class="flex md:justify-center lg:justify-start p-10">
        <vx-card class="card-rounded lg:w-3/4 md:w-5/6">
          <div class="px-8 pt-8 login-tabs-container">
            
            <vs-alert v-if="errQuery" active="true" color="warning" class="mb-4">
              {{ this.$t(errQuery) }}
            </vs-alert>

            <div class="vx-card__title mb-4">
              <p>Bienvenido aliado</p>
              <h1 class="mb-1 mt-10">{{ this.$t('Inicia Sesión') }}</h1>
            </div>

            <!-- <vs-tabs>
              <vs-tab label="JWT">
                <login-jwt></login-jwt>
              </vs-tab>

              <vs-tab label="Firebase">
                <login-firebase></login-firebase>
              </vs-tab>

              <vs-tab label="Auth0">
                <login-auth0></login-auth0>
              </vs-tab>
            </vs-tabs> -->

            <!-- The tabs has been disabled since created unique form -->

            <div class="pt-4">
              <login-siti></login-siti>
            </div>

          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

// import LoginJwt from "./LoginJWT.vue"
// import LoginFirebase from "./LoginFirebase.vue"
// import LoginAuth0 from "./LoginAuth0.vue"
import LoginSiti from "./LoginSiti.vue";

export default Vue.extend({
  components: {
    // LoginJwt,
    // LoginFirebase,
    // LoginAuth0,
    LoginSiti,
  },

  computed: {
    errQuery() {
      return this.$route.query.err;
    }
  },
})
</script>

<style lang="scss">
.card-rounded {
  border-radius: 10px;
}

.img-center{
  top: 50%;
  bottom: 50% !important;
  position: sticky;
}
</style>
